import { RouteRecordRaw } from "vue-router";
import { eguaranteeTypes } from "@ntprsrf-frontend/internet/src/constants/APIEnums";

const dataSettingsRoute: RouteRecordRaw[] = [
  {
    path: "data-settings",
    redirect: {
      name: "",
    },
    meta: {
      label: "API/SFTP Settings",
    },
    name: 'DATA_SETTINGS',
    children: [
      // {
      //   path: "data-consent",
      //   name: "DATA_CONSENT",
      //   meta: {
      //     label: "Data Consent",
      //     eguaranteeTypesCanAccess: [
      //       eguaranteeTypes.NORMAL.VALUE,
      //       eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
      //     ],
      //   },
      //   component: () => import("@/views/DataSettings/DataConsentList.vue"),
      // },
      {
        path: "api-connection",
        name: "API_CONNECTION",
        meta: {
          label: "API Settings",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE
          ],
        },
        component: () => import("@/views/DataSettings/APIConnection.vue"),
      },
      {
        path: "sftp-connection",
        name: "SFTP_CONNECTION",
        meta: {
          label: "SFTP",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
          ],
        },
        component: () => import("@/views/DataSettings/SFTPConnection.vue"),
        redirect: () => {
          return {
            name: "ACCOUNT",
          };
        },
        children: [
          {
            name: "ACCOUNT",
            path: "account",
            meta: {
              label: "Account",
              eguaranteeTypesCanAccess: [
                eguaranteeTypes.NORMAL.VALUE,
                eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
              ],
            },
            component: () => import("@/views/DataSettings/Account.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "data-settings",
    redirect: {
      name: "",
    },
    meta: {
      label: "API Settings",
    },
    name: 'GA_DATA_SETTINGS',
    children: [
      {
        path: "api-connection",
        name: "GA_API_CONNECTION",
        meta: {
          label: "API",
          eguaranteeTypesCanAccess: [
            eguaranteeTypes.NORMAL.VALUE,
            eguaranteeTypes.FINANCIAL_INSTITUTION.VALUE,
            eguaranteeTypes.GOVERNMENT_AGENCY.VALUE
          ],
        },
        component: () => import("@/views/DataSettings/APIConnection.vue"),
      },
    ],
  },
];

export default dataSettingsRoute;
